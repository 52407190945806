import React from "react";
import { useRef, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Pulse from "../components/pulse";

const Milestones = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 100vh;
  }
`;

const ScrollSnoop = ({ breakpoints, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      if (!ref.current || !breakpoints || breakpoints.length === 0) {
        return;
      }
      const { top, bottom } = ref.current.getBoundingClientRect();
      const mid = (top + bottom) / 2;
      const ratio = (window.innerHeight - mid) / window.innerHeight;
      breakpoints.forEach(({ min, max, callback }) => {
        if (min <= ratio && ratio < max) {
          callback();
        }
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [ref, breakpoints]);

  return <div ref={ref}>{children}</div>;
};

const Trap = ({ children, snare, unsnare }) => {
  const [aboveMidline, setAboveMidline] = useState(false);
  return (
    <ScrollSnoop
      breakpoints={[
        {
          min: 0.49,
          max: 0.7,
          callback: () => {
            if (!aboveMidline && snare) {
              snare();
            }
            setAboveMidline(true);
          },
        },
        {
          min: 0.1,
          max: 0.3,
          callback: () => {
            if (aboveMidline && unsnare) {
              unsnare();
            }
            setAboveMidline(false);
          },
        },
      ]}
    >
      <Pulse active={aboveMidline}>{children}</Pulse>
    </ScrollSnoop>
  );
};

const PreventScrolling = () => (
  <Global
    styles={css`
      html {
        overflow: hidden;
      }
    `}
  />
);

const useReversedScrolling = () => {
  const lastClientY = useRef(null);

  useEffect(() => {
    const mousewheel = e => {
      // go the *other* direction
      window.scrollBy(0, -e.deltaY);
    };

    lastClientY.current = null;

    const touchstart = e => {
      const { clientY } = e.touches[0];
      lastClientY.current = clientY;
    };

    const touchmove = e => {
      const { clientY } = e.touches[0];
      window.scrollBy(0, clientY - lastClientY.current);
      lastClientY.current = clientY;
    };

    window.addEventListener("mousewheel", mousewheel);
    window.addEventListener("touchstart", touchstart);
    window.addEventListener("touchmove", touchmove);

    return () => {
      window.removeEventListener("mousewheel", mousewheel);
      window.removeEventListener("touchstart", touchstart);
      window.removeEventListener("touchmove", touchmove);
    };
  }, []);
};

const MAX_STRUGGLES = 90;

const ReverseScrolling = () => {
  useReversedScrolling();
  return <></>;
};

// https://stackoverflow.com/a/14172194/2574937
function checkBrowser() {
  if (typeof navigator === "undefined") {
    return "";
  }
  if (navigator.userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (navigator.userAgent.includes("Chrome")) {
    return "Chrome";
  } else if (navigator.userAgent.includes("Safari")) {
    return "Safari";
  } else if (navigator.userAgent.includes("MSIE")) {
    return "IE";
  }
  return "";
}

// overflow: hidden + { behavior: "smooth" } are incompatible on firefox
const SCROLL_OPTS =
  checkBrowser() === "Firefox"
    ? { block: "center" }
    : { block: "center", behavior: "smooth" };

const UpIsDown = () => {
  useEffect(() => Prism.highlightAll());
  const startingPointRef = useRef(null);
  const struggleRef = useRef(null);
  const reverseRef = useRef(null);

  const [preventScrolling, setPreventScrolling] = useState(false);
  const [listenStruggles, setListenStruggles] = useState(false);
  const [struggles, setStruggles] = useState(0);
  const [goToReverse, setGoToReverse] = useState(false);
  const [scrollReverse, setScrollReverse] = useState(false);

  useEffect(() => {
    if (!listenStruggles) {
      return;
    }
    const struggle = () => {
      if (struggles < MAX_STRUGGLES) {
        setStruggles(struggles + 1);
      } else {
        setGoToReverse(true);
      }
    };
    window.addEventListener("mousewheel", struggle);
    window.addEventListener("touchmove", struggle);
    return () => {
      window.removeEventListener("mousewheel", struggle);
      window.removeEventListener("touchmove", struggle);
    };
  }, [listenStruggles, struggles]);

  useEffect(() => {
    if (goToReverse) {
      reverseRef.current.scrollIntoView(SCROLL_OPTS);
      const timeoutId = setTimeout(() => setScrollReverse(true), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [goToReverse]);

  return (
    <>
      <div id="top"></div>
      <Layout>
        <SEO title="Up is Down" />
        <h1>2. Up is Down</h1>
        <Milestones>
          <div>
            <Pulse active={true}>
              <p>Nice job!</p>
            </Pulse>
            <p ref={startingPointRef}>
              Up next, we'll be messing with scroll events. Have you ever been
              annoyed when you visited a website that broke scrolling?
            </p>
            <p>
              Imagine the damage if they were actually <em>trying</em> to be
              annoying!
            </p>
            <p>Scroll down to continue.</p>
          </div>

          <div>
            <p>
              Did you know? I can force the window to scroll wherever I want:
            </p>
            <Trap snare={() => window.scrollBy(0, 100)}>
              <pre>
                <code className="language-javascript">
                  {`// You're scrolling down by 100px,
// whether you want to or not.
window.scrollBy(0, 100);
`}
                </code>
              </pre>
            </Trap>
          </div>

          <div>
            <p>
              Of course, I don't want you scrolling for yourself. Let's keep you
              from scrolling at all.
            </p>

            <Trap snare={() => setPreventScrolling(true)}>
              <pre>
                <code className="language-css">{`html {
  overflow: hidden;
}`}</code>
              </pre>
            </Trap>
            {preventScrolling && (
              <>
                <PreventScrolling />
                <button onClick={() => setPreventScrolling(false)}>undo</button>
                <button
                  onClick={() => {
                    struggleRef.current.scrollIntoView(SCROLL_OPTS);
                    setListenStruggles(true);
                  }}
                >
                  next
                </button>
              </>
            )}
          </div>

          <div ref={struggleRef}>
            <p>Even though you can't scroll, you can still *try* to scroll.</p>
            <p>And I can watch you struggle:</p>
            <Pulse active={listenStruggles}>
              <pre>
                <code className="language-javascript">{`window.addEventListener("mousewheel", e => {
  // nice try!
  struggles++;
});`}</code>
              </pre>
            </Pulse>
            {struggles > 0 && (
              <Pulse active={struggles >= MAX_STRUGGLES}>
                <div>struggles:</div>
                <code>{`${"=".repeat(struggles)}>${"-".repeat(
                  MAX_STRUGGLES - struggles,
                )}|`}</code>
              </Pulse>
            )}
          </div>

          <div ref={reverseRef}>
            <p>
              Finally, we can take the scroll event and intentionally scroll the
              opposite way:
            </p>
            <Pulse active={scrollReverse}>
              <pre>
                <code className="language-javascript">{`window.addEventListener("mousewheel", e => {
// go the *other* direction
window.scrollBy(0, -e.deltaY);
});`}</code>
              </pre>
            </Pulse>
            {scrollReverse && <ReverseScrolling />}
          </div>

          <div>
            <p>You made it!</p>
            <p>
              Hope you learned a thing or two about how to (ab)use event
              listeners.
            </p>
            <Link to="/runaway">Next part: Runaway</Link>
          </div>
        </Milestones>
      </Layout>
    </>
  );
};

export default UpIsDown;
