// import React from "react";
import styled from "@emotion/styled";

const Pulse = styled.div`
  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
  }
  ${({ active }) => (active ? "animation: shadow-pulse 2s" : "")};
`;

export default Pulse;
